@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');


@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/static/fonts/landing/fa-regular-400.eot');
  src: url('/static/fonts/landing/fa-regular-400.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/landing/fa-regular-400.woff2') format('woff2'),
  url('/static/fonts/landing/fa-regular-400.woff') format('woff'),
  url('/static/fonts/landing/fa-regular-400.ttf') format('truetype'),
  url('/static/fonts/landing/fa-regular-400.svg#fontawesome') format('svg');
}

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url('/static/fonts/landing/fa-light-300.eot');
  src: url('/static/fonts/landing/fa-light-300.eot?#iefix') format('embedded-opentype'),
  url('/static/fonts/landing/fa-light-300.woff2') format('woff2'),
  url('/static/fonts/landing/fa-light-300.woff') format('woff'),
  url('/static/fonts/landing/fa-light-300.ttf') format('truetype'),
  url('/static/fonts/landing/fa-light-300.svg#fontawesome') format('svg');
}


.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

/* LAYOUT */

.wrapper {
  background-color: #eef0f2;
  padding: 2rem 1.5rem;
}

/* TYPOGRAPHY */

html {
  font-size: 15px;
}

body {
  font-family: 'Open Sans';
  font-size: 0.9375rem;
  line-height: 1.5em;
  color: #2a3545;
}

h1 {
  color: #2a3545;
  font-size: 1.625rem;
  font-weight: bold;
  line-height: 1.35em;
  margin-bottom: 0.2rem;
}

h4 {
  font-size: 0.9375rem;
  font-weight: bold;
  line-height: 1.5em;
  margin-bottom: 0.75rem; 
}

a {
  color: #2092de;
  font-weight: bold;
  text-decoration: none;
}

a:hover, a:focus, a:active {
  color: #1b7cbd;
  text-decoration: none;
}

.page-subtitle {
  font-size: 1.125rem;
  line-height: 1.5em;
  margin-bottom: 2rem;
}

@media (min-width: 360px) and (max-width: 767px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .page-subtitle {
    font-size: 1.25rem;
  }
}

@media (min-width: 992px) and (max-width: 1279px), (min-width: 1440px) {
  html {
    font-size: 16px;
  }
}

/* BUTTONS */

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9375rem;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  white-space: break-spaces;
}

.btn-primary {
  background-color: #2092de;
  color: #fff;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: #1b7cbd !important;
  color: #fff;
}

.btn-secondary {
  background-color: transparent;
  border: 2px solid #2092de;
  color: #2092de;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
  background-color: #2092de !important;
  border-color: #2092de !important;
  color: #fff;
}

.btn-link {
  color: #2092de;
  font-weight: bold;
  text-decoration: none;
  padding: 0.35rem 1rem;
}

.btn-link:hover {
  color: #1b7cbd;
  text-decoration: none;
}

.btn-primary + .btn-link {
  margin-top: 0.75rem;
}

/* CARDS */

.card {
  background-color: #fff;
  border-radius: 5px;
  margin: 0 auto 1.5rem;
  height: calc(100% - 1.5rem);
  display: flex;
  flex-direction: column;
  border: none;
}

.card-header {
  background-color: rgba(0, 0, 0, 0.03);
  height: 8.25rem;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.card-img {
  width: auto;
  max-width: 70%;
  max-height: 100%; 
}

.card-body {
  padding: 1.5rem;
  flex: 1 1 auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.card-title {
  text-transform: uppercase;
}

.card-footer {
  padding: 0rem 1rem 1.5rem;  
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: none;
  background-color: transparent;
}

@media (min-width: 576px) {
  .card-footer {
    min-height: 116px;
  }
}

/* CARD VARIANTS */

.card--more-services,
.card--contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.card--contact {
  background-color: #CFE2EF;
}

.card--more-services .card-body,
.card--contact .card-body {
  padding: 2rem;
  flex: none; 
}

.card--contact i {
  color: #2092de;
  margin-bottom: 1rem;
}

.card--contact a {
  display: block;
  width: 100%;
}