.highcharts-tooltip-custom {
    padding: 20px;
    background-color: #EFEFF2;
    color: #4D4F5C;
    font-family: Roboto-Regular;
    font-size: 14px;
}

.eco-chart .highcharts-data-label span, .pai-chart .highcharts-data-label span {
    overflow: visible !important;
}

.eco-chart .highcharts-data-labels .highcharts-data-label:nth-child(1) span{
    left: -5px!important;
    top: 10px!important;
}
.eco-chart 
.highcharts-data-labels .highcharts-data-label:nth-child(2) span{
    left: -20px!important;
    top: -10px!important;
}
.eco-chart 
.highcharts-data-labels .highcharts-data-label:nth-child(3) span{
    left: -5px!important;
    top: -30px!important;
}
.eco-chart 
.highcharts-data-labels .highcharts-data-label:nth-child(4) span{
    left: 15px!important;
    top: -30px!important;
}
.eco-chart 
.highcharts-data-labels .highcharts-data-label:nth-child(5) span{
    left: 30px!important;
    top: -10px!important;
}

.eco-chart .highcharts-data-labels .highcharts-data-label:nth-child(6) span{
    left: 15px!important;
    top: 10px!important;

}

/*--------------*/


.pai-chart .highcharts-data-labels .highcharts-data-label:nth-child(1) span{
    left: -5px!important;
    top: 10px!important;
}
.pai-chart 
.highcharts-data-labels .highcharts-data-label:nth-child(2) span{
    left: -15px!important;
    top: 0px!important;
}
.pai-chart 
.highcharts-data-labels .highcharts-data-label:nth-child(3) span{
    left: -5px!important;
    top: -20px!important;
}
.pai-chart 
.highcharts-data-labels .highcharts-data-label:nth-child(4) span{
    left: 7px!important;
    top: -30px!important;
}
.pai-chart 
.highcharts-data-labels .highcharts-data-label:nth-child(5) span{
    left: 12px!important;
    top: -18px!important;
}

.pai-chart .highcharts-data-labels .highcharts-data-label:nth-child(6) span{
    left: 22px!important;
    top: 0px!important;
}

.pai-chart .highcharts-data-labels .highcharts-data-label:nth-child(7) span{
    left: 15px!important;
    top: 13px!important;
}

.ESGChart .highcharts-axis-labels span:nth-child(1){
    left: 295px!important;
    top: 88px!important;
}

.ESGChart .highcharts-axis-labels span:nth-child(2){
    left: 320px!important;
    top: 250px!important;
}

.ESGChart .highcharts-axis-labels span:nth-child(3){
    left: 190px!important;
    top: 190px!important;
}

